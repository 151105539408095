import React from "react";
import { useHistory, useLocation } from "react-router";
import { sanitize } from "dompurify";
import { sessionCardStyles } from "../styles/SessionCard";
import { IEventSession } from "../core/slices/session.interface";
import { useDispatch, useSelector } from "react-redux";
import { EventUser } from "../core/slices/userTypes.interface";
import { useTranslation } from "react-i18next";
import {
  ButtonV9 as Button,
  CalendarAddRegular,
  MenuV9 as Menu,
  MenuTriggerV9 as MenuTrigger,
  MenuPopoverV9 as MenuPopover,
  MenuListV9 as MenuList,
  mergeClasses,
  TextV9 as Text,
  TooltipV9 as Tooltip,
  AvatarV9 as Avatar,
  Link,
} from "../shared";
import { enableAddToCalenderSelector } from "../core/slices/ecsSlice";
import { useBreakpoint } from "../utilities/hooks/useBreakpoints";
import { Breakpoint } from "../styles/Grid";
import { eventSessionsAddToCalendarAsyncAction } from "../core/slices/sessionSlice";
import { AddToCalenderMenuItems } from "../utilities/addToCalendarUtils";
import { eventSelector, userIsRegistered } from "../core/slices/eventSlice";
import {
  flexAlignStyles,
  flexStyles,
  flexItemStyles,
} from "../styles/FlexStyles";
import { portalTextStyles } from "../styles/PortalText";
import { portalListStyles } from "../styles/PortalList";
import { DateTimeDisplay } from "../shared/TimeDisplay";
import { formatDateTime } from "../utilities/common/dateUtils";
import { SpeakerImage } from "./SpeakerCard";
import { timeDisplayStyles } from "../styles/TimeDisplay";
import { routes } from "../common/constants";
import { generateSearchParams } from "../utilities/common/generateSearchParams";
import { PortalLocationState } from "../core/history/history.interface";

export const SessionCard: React.FunctionComponent<{
  session: IEventSession;
}> = ({ session }) => {
  const flexItemClasses = flexItemStyles();
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const sessionCardClasses = sessionCardStyles();
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);
  const isRegistered = useSelector(userIsRegistered);

  return (
    <div
      data-testid="sessionCard"
      className={mergeClasses(
        "session-card",
        flexClasses.root,
        flexClasses.column,
        sessionCardClasses.root
      )}
    >
      <div
        className={mergeClasses(
          "session-card-body",
          flexClasses.root,
          flexClasses.rowGap16px,
          sessionCardClasses.section
        )}
      >
        <SessionTimings session={session} />
        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            flexClasses.columnGap16px,
            flexClasses.fill
          )}
        >
          <SessionInfoAndDescription session={session} />

          <div
            className={mergeClasses(
              flexClasses.root,
              flexClasses.rowGapMedium,
              flexAlignClasses.alignItemEnd
            )}
          >
            <SessionPresenters session={session} />
            {isRegistered && !isMobileView && (
              <div
                className={mergeClasses(
                  flexClasses.row,
                  flexItemClasses.rowPush
                )}
              >
                <SessionAddToCalendarButton session={session} />
              </div>
            )}
          </div>
        </div>
      </div>
      {isRegistered && isMobileView && (
        <div
          className={mergeClasses(
            "session-card-footter",
            flexClasses.root,
            flexClasses.rowGap20px,
            sessionCardClasses.section
          )}
        >
          <SessionAddToCalendarButton session={session} />
        </div>
      )}
    </div>
  );
};

export const SessionTimings: React.FunctionComponent<{
  session: IEventSession;
  dateText?: { className: string };
  dayText?: { className: string };
  isSpeakerSessionCard?: boolean;
}> = ({ session, dateText, dayText, isSpeakerSessionCard = false }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const sessionCardClasses = sessionCardStyles();
  const startTime = new Date(session.sessionTime.startTime);
  const { t: i18n } = useTranslation();
  return (
    <div
      className={mergeClasses(
        "session-card-date",
        flexClasses.root,
        flexClasses.column,
        sessionCardClasses.date
      )}
    >
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.row,
          flexAlignClasses.alignItemEnd,
          flexAlignClasses.justifyContentCenter,
          sessionCardClasses.dateTime
        )}
        data-testid="sessionDateMonth"
      >
        {isSpeakerSessionCard ? (
          <>
            <Text
              className={mergeClasses(
                portalTextClasses.size18px,
                sessionCardClasses.sessionDate,
                dateText?.className
              )}
            >
              {`${formatDateTime(
                startTime,
                { day: "numeric" },
                i18n
              )} ${formatDateTime(startTime, { month: "short" }, i18n)}`}
            </Text>
          </>
        ) : (
          <>
            <Text
              className={mergeClasses(
                portalTextClasses.size18px,
                sessionCardClasses.sessionDate,
                dateText?.className
              )}
            >
              {formatDateTime(startTime, { day: "numeric" }, i18n)}
            </Text>
            <Text
              className={mergeClasses(
                portalTextClasses.smaller,
                sessionCardClasses.sessionMonth,
                dayText?.className
              )}
            >
              {formatDateTime(startTime, { month: "short" }, i18n)}
            </Text>
          </>
        )}
      </div>
      <div
        data-testid="SessionDateDay"
        className={mergeClasses(
          flexClasses.root,
          flexAlignClasses.alignItemCenter,
          flexAlignClasses.justifyContentCenter,
          sessionCardClasses.dateDuration
        )}
      >
        <Text
          className={mergeClasses(
            portalTextClasses.small,
            portalTextClasses.colorBrand
          )}
          align="center"
        >
          {formatDateTime(startTime, { weekday: "long" }, i18n)}
        </Text>
      </div>
    </div>
  );
};

export const SessionInfoAndDescription: React.FunctionComponent<{
  session: IEventSession;
  isSpeakerSessionCard?: boolean;
  sessionTitleClassName?: string;
}> = ({ session, isSpeakerSessionCard = false, sessionTitleClassName }) => {
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const sessionCardClasses = sessionCardStyles();
  const timeDisplayClasses = timeDisplayStyles();
  const sessionDescriptionText = session?.description;
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();
  const currentEvent = useSelector(eventSelector);

  const onClickSession = () => {
    if (currentEvent) {
      history.push({
        pathname: `${routes.event}/${currentEvent?.id}/${routes.session}/${session.id}`,
        search: generateSearchParams(location),
      });
    }
  };

  /* eslint-disable react/no-danger */
  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        isSpeakerSessionCard
          ? flexClasses.columnGap4px
          : flexClasses.columnGapSmaller
      )}
    >
      <Link
        data-testid="sessionTitle"
        className={mergeClasses(
          portalTextClasses.lineHeight26px,
          sessionCardClasses.sessionTitle,
          portalTextClasses.colorBrand,
          sessionTitleClassName
        )}
        aria-label={session.title}
        appearance="subtle"
        onClick={onClickSession}
      >
        {session.title}
      </Link>
      <DateTimeDisplay
        startTime={session?.sessionTime?.startTime}
        endTime={session?.sessionTime?.endTime}
        format="time"
        className={
          isSpeakerSessionCard
            ? timeDisplayClasses.speakerSessionTime
            : undefined
        }
      />
      {!isSpeakerSessionCard && sessionDescriptionText && (
        <Text size={400} className={sessionCardClasses.sessionDescriptionText}>
          <span
            className={sessionCardClasses.sessionDescriptionInSpan}
            dangerouslySetInnerHTML={{
              __html: sanitize(sessionDescriptionText, {
                RETURN_TRUSTED_TYPE: true,
              }).toString(),
            }}
          />
        </Text>
      )}
    </div>
  );
  /* eslint-enable react/no-danger */
};

const SessionPresenters: React.FunctionComponent<{
  session: IEventSession;
}> = ({ session }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const sessionCardClasses = sessionCardStyles();
  const { t: i18n } = useTranslation();
  const speakers: EventUser[] = session.speakers;
  const visibleSpeakers = speakers.slice(0, 3);
  const hiddenSpeakers = speakers.slice(3);
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);
  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.row,
        isMobileView ? flexClasses.rowGapSmaller : flexClasses.rowGap16px,
        sessionCardClasses.sessionPresenters
      )}
      aria-label={i18n("list_of_speakers")}
      data-testid="sessionPresenters"
    >
      {visibleSpeakers.map((speaker) => {
        return (
          <div
            key={speaker.id}
            className={mergeClasses(
              flexClasses.root,
              flexClasses.row,
              flexClasses.rowGapSmaller,
              flexAlignClasses.alignItemCenter
            )}
          >
            <SpeakerImage speaker={speaker} size={24} />
            {!isMobileView && (
              <Text
                key={speaker.id}
                className={mergeClasses(
                  portalTextClasses.small,
                  sessionCardClasses.avatarName
                )}
                align="center"
                truncate={true}
                wrap={false}
              >
                {speaker.displayName}
              </Text>
            )}
          </div>
        );
      })}
      {hiddenSpeakers.length > 0 && (
        <HiddenSpeakers hiddenSpeakers={hiddenSpeakers} />
      )}
    </div>
  );
};

const HiddenSpeakers: React.FunctionComponent<{
  hiddenSpeakers: EventUser[];
}> = ({ hiddenSpeakers }) => {
  const HiddenSpeakersContent: React.FunctionComponent<{
    hiddenSpeakers: EventUser[];
  }> = ({ hiddenSpeakers }) => {
    const portalTextClasses = portalTextStyles();
    return (
      <>
        {hiddenSpeakers.map((speaker: EventUser) => (
          <div key={speaker.id}>
            <Text className={portalTextClasses.small}>
              {speaker.displayName}
            </Text>
          </div>
        ))}
      </>
    );
  };
  return (
    <Tooltip
      content={<HiddenSpeakersContent hiddenSpeakers={hiddenSpeakers} />}
      relationship="label"
      withArrow={true}
      positioning="below"
    >
      <Avatar
        aria-hidden={true}
        tabIndex={-1}
        initials={`+${hiddenSpeakers.length}`}
        size={24}
        data-testid="speakersTooltip"
      />
    </Tooltip>
  );
};

export const SessionCardList: React.FunctionComponent<{
  sessions: IEventSession[];
}> = ({ sessions }) => {
  const portalListClasses = portalListStyles();
  return (
    <ul className={portalListClasses.root} data-testid="sessionsList">
      {sessions.map((session) => (
        <li key={session.id}>
          <SessionCard session={session} />
        </li>
      ))}
    </ul>
  );
};

const SessionAddToCalendarButton: React.FunctionComponent<{
  session: IEventSession;
}> = ({ session }) => {
  const { t: i18n } = useTranslation();
  const enableAddToCalender = useSelector(enableAddToCalenderSelector);
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Small);
  const dispatch = useDispatch();
  return (
    <>
      {enableAddToCalender && (
        <Menu
          positioning={
            /* istanbul ignore next */
            isMobileView
              ? { align: "start", offset: { crossAxis: 0, mainAxis: 8 } }
              : { align: "end", offset: { crossAxis: 0, mainAxis: 8 } }
          }
        >
          <MenuTrigger>
            <Button
              icon={<CalendarAddRegular />}
              title={i18n("button_add_to_calender")}
              aria-label={i18n("button_add_to_calender")}
            >
              {isMobileView ? i18n("button_add_to_calender") : null}
            </Button>
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              <AddToCalenderMenuItems
                onClick={(type) => {
                  dispatch(
                    eventSessionsAddToCalendarAsyncAction({
                      session,
                      type,
                    })
                  );
                }}
                i18n={i18n}
              />
            </MenuList>
          </MenuPopover>
        </Menu>
      )}
    </>
  );
};
