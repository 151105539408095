import React, { useEffect, useState } from "react";
import {
  IEmbedLoaderOptions,
  IEmbedPlaybackSessionOptions,
  IPlaybackSession,
  ITokenRetriever,
  OnePlayerError,
  TokenType,
  createWebinarGenericFileEmbedLoader,
} from "@msstream/components-oneplayer-iframe-api";
import { useLogger } from "../../common/logger/LoggerContext";
import { isDevelopment } from "../../utilities/common/utils";
import { useSelector } from "react-redux";
import { eventSelector } from "../../core/slices/eventSlice";
import { onePlayerStyles } from "./OnePlayer.styles";
import { PlayerLoading } from "../PlayerLoading";
import { Scenario } from "../../common/logger/Logger";
import { IRecordingPlaybackInfo } from "../../core/slices/session.interface";
import {
  getWindow,
  mergeClasses,
  MessageBar,
  MessageBarBody,
} from "../../shared";
import { useAuthenticationService } from "../../core/auth/auth-context";
import { AccountInfo } from "@azure/msal-browser";
import { useTranslation } from "react-i18next";
import { ILogger, ITelemetryData } from "../../common/logger/interface";
import { disableOneplayerIosSupportErrorMessageSelector } from "../../core/slices/ecsSlice";

const PLAYBACK_SESSION_OPTIONS_HOST_APP = "TeamsVirtualEventsPortal";
const PLAYBACK_SESSION_OPTIONS_HOST_PLATFORM = "Web";
const PLAYBACK_SESSION_OPTIONS_HOST_MODE = "view";

export interface IOnePlayerProps {
  publishTimeStamp: string;
  playbackInfo?: IRecordingPlaybackInfo;
  badgerToken?: string;
  account: AccountInfo | null;
  hostView: string;
}

export interface IInternalOnePlayerProps {
  publishTimeStamp: string;
  playbackInfo?: IRecordingPlaybackInfo;
  badgerToken?: string;
  account: AccountInfo | null;
  hostView: string;
}

export const OnePlayer: React.FunctionComponent<IOnePlayerProps> = ({
  publishTimeStamp,
  playbackInfo,
  badgerToken,
  account,
  hostView,
}) => (
  <InternalOnePlayer
    key={playbackInfo?.id}
    publishTimeStamp={publishTimeStamp}
    playbackInfo={playbackInfo}
    badgerToken={badgerToken}
    account={account}
    hostView={hostView}
  />
);

const InternalOnePlayer: React.FunctionComponent<IInternalOnePlayerProps> = ({
  publishTimeStamp,
  playbackInfo,
  badgerToken,
  account,
  hostView,
}) => {
  const onePlayerclasses = onePlayerStyles();

  const window = getWindow();
  const authenticationService =
    useAuthenticationService().authenticationService;
  const logger = useLogger().logger;
  const {
    t: i18n,
    i18n: { resolvedLanguage },
  } = useTranslation();

  const [playerContainer, setPlayerContainer] =
    React.useState<HTMLDivElement | null>(null);
  const [playbackSession, setPlaybackSession] = useState<
    IPlaybackSession | undefined
  >(undefined);
  const [fatalError, setFatalError] = React.useState<unknown | undefined>(
    undefined
  );
  const [didMount, setDidMount] = React.useState<boolean>(false);

  const playbackSessionId = React.useRef<string | undefined>(undefined);

  const unsubscribePlaybackSessionId = React.useRef<() => void>();
  const unsubscribeIsPlayerReady = React.useRef<() => void>();
  const unsubscribeTtlMs = React.useRef<() => void>();
  const unsubscribeTtirMs = React.useRef<() => void>();
  const unsubscribePlayState = React.useRef<() => void>();

  const currentEvent = useSelector(eventSelector);
  const eventTheme = currentEvent?.theme;

  const enableOneplayerIosSupportErrorMessage = !useSelector(
    disableOneplayerIosSupportErrorMessageSelector
  );

  useEffect(() => {
    if (
      playerContainer != null &&
      playbackInfo &&
      (account || badgerToken) &&
      !didMount
    ) {
      const setupScenario = logger.createScenario(Scenario.OnePlayerSetup, {
        data: { publishTimeStamp },
      });
      logger.createScenario(Scenario.OnePlayerPlayerReady, {
        data: { publishTimeStamp },
      });

      const timestamp = performance.now();
      const timeOrigin = performance.timeOrigin;

      const checkTokenType = async (tokenType: TokenType) => {
        // OnePlayer only supports ODSP videos for now
        if (tokenType !== "SPO") {
          return Promise.reject(`Unsupported token type ${tokenType}`);
        }
      };

      const getSpoToken = async (
        resourceOrigin: string,
        account: AccountInfo
      ) => {
        const url = new URL(resourceOrigin);
        const res = await authenticationService.acquireToken(
          account,
          `${url.origin}/.default`
        );
        return res.accessToken;
      };

      const tokenRetriever: ITokenRetriever = {
        beginSilentSignOn:
          /* istanbul ignore next */
          () => {
            // Do nothing.
          },
        // getTokenAsync is required for the ITokenRetriever interface but does not support badger token. This should be deprecate in favor of getTokenWithAuthTypeAsync at some point.
        getTokenAsync: async (tokenType: TokenType, resourceOrigin: string) => {
          await checkTokenType(tokenType);
          if (account) {
            return getSpoToken(resourceOrigin, account);
          } else {
            return Promise.reject("Unsupported scenario");
          }
        },
        getTokenWithAuthTypeAsync: async (
          tokenType: TokenType,
          resourceOrigin: string
        ) => {
          await checkTokenType(tokenType);
          if (account) {
            return { token: await getSpoToken(resourceOrigin, account) };
          } else if (badgerToken) {
            return { token: badgerToken, authType: "Badger" };
          } else {
            return Promise.reject("Unsupported scenario");
          }
        },
      };

      const loaderOptions: IEmbedLoaderOptions = {
        tokenRetriever,
      };

      const onError = (
        disposeSession: () => void,
        onePlayerError: OnePlayerError
      ) => {
        const scenario =
          logger.findScenario(Scenario.OnePlayerPlayback) ||
          logger.findScenario(Scenario.OnePlayerPlayerReady) ||
          logger.createScenario(Scenario.OnePlayerUnbinned);

        const data: ITelemetryData = {
          publishTimeStamp,
        };

        if (onePlayerError.playbackSessionId || playbackSessionId.current) {
          data.playbackSessionId =
            onePlayerError.playbackSessionId || playbackSessionId.current || "";
        }

        if (onePlayerError.level === "fatal") {
          if (
            enableOneplayerIosSupportErrorMessage &&
            isOneplayerErrorIosVideoError(onePlayerError, logger)
          ) {
            scenario?.stop({
              message: `OnePlayer onError callback called, iOS video error. ${getOneplayerErrorDetails(
                onePlayerError
              )}`,
              data,
            });
          } else if (isExpectedFatalOneplayerError(onePlayerError)) {
            scenario?.stop({
              message: `OnePlayer onError callback called, known fatal error. ${getOneplayerErrorDetails(
                onePlayerError
              )}`,
              data,
            });
          } else {
            scenario?.fail({
              message: `OnePlayer onError callback called, Fatal error. ${getOneplayerErrorDetails(
                onePlayerError
              )}`,
              data,
            });
          }
          setFatalError(onePlayerError);
        } else {
          scenario?.mark(onePlayerError.scenario, undefined, {
            message: `OnePlayer onError callback called. level: ${getOneplayerErrorDetails(
              onePlayerError
            )}`,
            data,
          });
          if (scenario?.name === Scenario.OnePlayerUnbinned) {
            scenario?.stop();
          }
        }
      };

      const videoUrl = new URL(playbackInfo.streamingUrl);
      videoUrl.searchParams.set("playbackToken", playbackInfo.token);

      const playbackSessionOptions: IEmbedPlaybackSessionOptions = {
        hostApp: PLAYBACK_SESSION_OPTIONS_HOST_APP,
        hostView: hostView,
        hostPlatform: PLAYBACK_SESSION_OPTIONS_HOST_PLATFORM,
        hostMode: PLAYBACK_SESSION_OPTIONS_HOST_MODE,
        hostEnvironment: isDevelopment() ? "Dev" : "Prod",
        hostSessionId: logger.sessionId,
        hostTimestamps: {
          t0: {
            timestamp,
            timeOrigin,
          },
        },
        videoId: {
          videoUrl: videoUrl.toString(),
        },
        containerDiv: playerContainer,
        allowFullscreen: true,
        onError,
        overrideLanguage: resolvedLanguage,
        theme: {
          primaryColor: eventTheme?.primaryColor,
        },
        hideVideoMetadata: true,
      };

      const initPlaybackSession = async (
        loaderOptions: IEmbedLoaderOptions,
        playbackSessionOptions: IEmbedPlaybackSessionOptions
      ) => {
        try {
          const loader = createWebinarGenericFileEmbedLoader(loaderOptions);
          const _playbackSession = await loader.createPlaybackSession(
            false,
            playbackSessionOptions
          );
          setPlaybackSession(_playbackSession);

          setupScenario?.stop();
        } catch (err) {
          let message = "OnePlayer create playback session promise rejected.";
          if (err instanceof OnePlayerError) {
            message += " " + getOneplayerErrorDetails(err);
          }
          setupScenario?.fail({
            message,
            data: { publishTimeStamp },
          });
          setFatalError(err);
        }
      };
      initPlaybackSession(loaderOptions, playbackSessionOptions);
      setDidMount(true);
    }
  }, [
    account,
    authenticationService,
    badgerToken,
    didMount,
    enableOneplayerIosSupportErrorMessage,
    eventTheme?.primaryColor,
    hostView,
    logger,
    playbackInfo,
    playerContainer,
    publishTimeStamp,
    resolvedLanguage,
  ]);

  useEffect(() => {
    if (playbackSession) {
      unsubscribePlaybackSessionId.current =
        playbackSession.playerStats.playbackSessionId.subscribe((value) => {
          if (value) {
            playbackSessionId.current = value;

            const playerReadyScenario = logger.findScenario(
              Scenario.OnePlayerPlayerReady
            );
            playerReadyScenario?.mark(
              "PlayerStats.playbackSessionId",
              undefined,
              {
                data: {
                  playbackSessionId: value,
                },
              }
            );
          }
        });

      unsubscribeIsPlayerReady.current =
        playbackSession.playbackApi.isPlayerReady.subscribe((value) => {
          if (value) {
            const eventData = {
              data: {
                playbackSessionId: playbackSessionId.current || "",
              },
            };
            const playerReadyScenario = logger.findScenario(
              Scenario.OnePlayerPlayerReady
            );
            playerReadyScenario?.mark("PlayerReady", undefined, eventData);
            playerReadyScenario?.stop();
          }
        });

      unsubscribePlayState.current =
        playbackSession.playbackApi.playState.subscribe((value) => {
          if (value) {
            const eventData = {
              data: {
                playbackSessionId: playbackSessionId.current || "",
              },
            };

            switch (value) {
              case "Playing":
                const newPlaybackScenario = logger.createScenario(
                  Scenario.OnePlayerPlayback,
                  eventData
                );
                newPlaybackScenario?.mark("Playing", undefined, eventData);
                break;
              case "Paused":
                // NOTE: Paused state will be triggered when playback is forced to pause when changing tabs or apps on mobile.
                const playbackScenario = logger.findScenario(
                  Scenario.OnePlayerPlayback
                );
                playbackScenario?.mark("Paused", undefined, eventData);
                playbackScenario?.stop();
                break;
            }
          }
        });

      unsubscribeTtlMs.current = playbackSession.playerStats.ttlMs.subscribe(
        (value) => {
          if (value) {
            const data = {
              playbackSessionId: playbackSessionId.current || "",
            };
            const scenario = logger.createScenario(Scenario.OnePlayerUnbinned, {
              data,
            });
            scenario?.mark("PlayerStats.ttlMs", undefined, {
              data: {
                ...data,
                ttlMs: value,
              },
            });
            scenario?.stop();
          }
        }
      );

      unsubscribeTtirMs.current = playbackSession.playerStats.ttirMs.subscribe(
        (value) => {
          if (value) {
            const data = {
              playbackSessionId: playbackSessionId.current || "",
            };
            const scenario = logger.createScenario(Scenario.OnePlayerUnbinned, {
              data,
            });
            scenario?.mark("PlayerStats.ttirMs", undefined, {
              data: {
                ...data,
                ttirMs: value,
              },
            });
            scenario?.stop();
          }
        }
      );

      return () => {
        playbackSession?.dispose();

        unsubscribePlaybackSessionId.current &&
          unsubscribePlaybackSessionId.current();
        unsubscribePlaybackSessionId.current = undefined;

        unsubscribeIsPlayerReady.current && unsubscribeIsPlayerReady.current();
        unsubscribeIsPlayerReady.current = undefined;

        unsubscribeTtlMs.current && unsubscribeTtlMs.current();
        unsubscribeTtlMs.current = undefined;

        unsubscribeTtirMs.current && unsubscribeTtirMs.current();
        unsubscribeTtirMs.current = undefined;

        unsubscribePlayState.current && unsubscribePlayState.current();
        unsubscribePlayState.current = undefined;

        const eventData = {
          data: {
            playbackSessionId:
              playbackSession.playerStats.playbackSessionId.value || "",
          },
        };

        // Ensure playback scenario ends when component unmounts (eg. SPA navigation and component is no longer rendered).
        // PlayState subscription will not change to pause when component unmounts.
        const playbackScenario = logger.findScenario(
          Scenario.OnePlayerPlayback
        );
        playbackScenario?.mark("component.unmount", undefined, eventData);
        playbackScenario?.stop();
      };
    }
  }, [logger, playbackSession, publishTimeStamp]);

  // Ensure playback scenario is ended when session ends (eg. page reload/navigation, and close tab/window/browser).
  // playState subscription will not change to pause for these scenarios.
  // NOTE: This will not catch when the process ends abruptly
  React.useEffect(() => {
    const pagehide = () => {
      const playbackScenario = logger.findScenario(Scenario.OnePlayerPlayback);
      if (playbackScenario) {
        playbackScenario?.mark("window.pagehide");
        playbackScenario?.stop();
      }
    };
    window?.addEventListener("pagehide", pagehide);
    return () => {
      window?.removeEventListener("pagehide", pagehide);
    };
  }, [logger, window]);

  if (
    enableOneplayerIosSupportErrorMessage &&
    !!fatalError &&
    isOneplayerErrorIosVideoError(fatalError, logger)
  ) {
    return (
      <MessageBar intent={"error"}>
        <MessageBarBody>
          {i18n("recording_oneplayer_ios_not_supported_message")}
        </MessageBarBody>
      </MessageBar>
    );
  }

  return (
    <div>
      {!playbackSession && <PlayerLoading />}
      <div
        className={mergeClasses(!playbackSession && onePlayerclasses.hidden)}
      >
        <div
          data-testid={playbackSession && "one-player"}
          ref={setPlayerContainer}
          className={onePlayerclasses.container}
        />
      </div>
    </div>
  );
};

const getOneplayerErrorDetails: (onePlayerError: OnePlayerError) => string = (
  onePlayerError
) =>
  `level: ${onePlayerError.level}, isExpected: ${onePlayerError.isExpected}, scenerio: ${onePlayerError.scenario}, knownError: ${onePlayerError.knownError}, playbackSessionId: ${onePlayerError.playbackSessionId}, hostSessionId: ${onePlayerError.hostSessionId}, message: ${onePlayerError.message}`;

const isOneplayerErrorIosVideoError: (
  onePlayerError: unknown,
  logger: ILogger
) => boolean = (onePlayerError, logger) => {
  const osName = logger.getDeviceInfo().osName;
  if (!(onePlayerError instanceof OnePlayerError)) {
    return false;
  }

  return (
    osName === "iOS" &&
    onePlayerError.knownError === "playbackUnknown" &&
    onePlayerError.scenario === "playback" &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (onePlayerError.originalError as any)?.scenario === "loadPlayer" &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (onePlayerError.originalError as any)?.knownError === "engineRender" &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (onePlayerError.originalError as any)?.message === "VIDEO_ERROR"
  );
};

// Errors that should not fail logger scenarios.
// Note: This is what the portal expected and is different than what OnePlayer expects (OnePlayerError.isExpected).
const isExpectedFatalOneplayerError: (onePlayerError: unknown) => boolean = (
  onePlayerError
) => {
  if (!(onePlayerError instanceof OnePlayerError)) {
    return false;
  }

  return (
    onePlayerError.level === "fatal" &&
    onePlayerError.scenario === "playback" &&
    (onePlayerError.message === "MEDIA_ERR_SRC_NOT_SUPPORTED" ||
      onePlayerError.message === "HTTP_STATUS_404" ||
      onePlayerError.message === "NETWORK_DOWN" ||
      onePlayerError.message === "NETWORK_EXCEPTION")
  );
};
