import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Logger, Scenario } from "../../common/logger/Logger";
import { initialEcsConfig } from "../../configs/initialEcsConfigs";
import { LoggerLevels } from "../../common/logger/interface";
import {
  EcsRequestStatus,
  IEcsClient,
  IEcsFilters,
  IPortalEcsConfiguration,
} from "../ecs/interface";
import { RootState } from "../store/store";
import { userOIDSelector, userTenantIDSelector } from "./userSlice";
import { generateEcsParameters } from "../ecs/ecsClient";

export interface IEcsState {
  isConfigSet: boolean; // Note: Will be set to true even if initial ECS fetch fails, so that ECS issues will not block usage of the portal.
  configIDs: string;
  config: IEcsConfig;
}

/**
 * When adding ECS config:
 * - Keep in alphabetical order.
 * - Add base config value to core/ecs/initial-config/config-base.json.
 * - Add config in ECS portal (https://ecs.skype.com/MicrosoftTeams/VirtualEventsPortal/configurations?view=all)
 **/
export interface IEcsConfig {
  baseAmsUrl: string | undefined;
  disableRegionRouting: boolean;
  disableOneplayerIosSupportErrorMessage: boolean;
  enableAADTokenForAMS: boolean;
  enableAccountSettingsPage: boolean;
  enableAddToCalendar: boolean;
  enableBrb: boolean;
  enableDownloadLogsButton: boolean;
  enableEditAccount: boolean;
  enableEventOverviewApi: boolean;
  enableEventSharingSocialButtons: boolean;
  enablePublicProfilePage: boolean;
  enableRedirectAcquireToken: boolean;
  enableRedirectLogin: boolean;
  enableCreateWebinar: boolean;
  enableSocialLogin: boolean;
  enableSponsors: boolean;
  enableVODPlayback: boolean;
  enableWebinarSeries: boolean;
  enableWorkAccountProfilePages: boolean;
  showEditAccountNonMvpFields: boolean; // MVP fields: Photo, First name, Last name, Display name, Email
  showEngagementRail: boolean;
  virtualEventsServiceUrl: string | undefined;
}

const initialState: IEcsState = {
  isConfigSet: false,
  configIDs: "",
  config: initialEcsConfig || {},
};

let isEcsInitiated = false;

export const setEcsInitiated: (value: boolean) => void = (value) => {
  isEcsInitiated = value;
};

export const initEcsConfigAction = createAsyncThunk(
  "ecs/config/init",
  async (ecsClient: IEcsClient, { dispatch, getState }) => {
    if (isEcsInitiated) {
      return;
    }
    isEcsInitiated = true;

    const logger = Logger.getInstance();
    const scenario = logger.createScenario(Scenario.LoadEcsConfig);

    let error;

    try {
      const filters: IEcsFilters = {};

      const tenantID = userTenantIDSelector(getState() as RootState);
      if (tenantID) {
        filters.TenantID = tenantID;
      }

      const userOID = userOIDSelector(getState() as RootState);
      if (userOID) {
        filters.UserID = userOID;
      }

      ecsClient.updateParameters(generateEcsParameters(filters));
      const ecsData = await ecsClient.fetch();
      if (!ecsData.ecsConfiguration) {
        error = `ECS configuration is undefined. EcsRequestStatus: ${ecsData.requestStatus}`;
      }
      const message = ecsData.ecsConfiguration
        ? `EcsRequestStatus: ${ecsData.requestStatus}, Config IDs: ${ecsData.ecsConfiguration.ConfigIDs.VirtualEventsPortal}`
        : `EcsRequestStatus: ${ecsData.requestStatus}`;
      scenario?.mark(
        "ecsInitialFetch - resolved",
        EcsRequestStatus[ecsData.requestStatus],
        {
          message,
        }
      );
      await dispatch(setEcsConfigAction(ecsData.ecsConfiguration));
    } catch (_error) {
      error = _error;
      scenario?.mark("ecsInitialFetch - rejected");
      await dispatch(setEcsConfigAction());
    }

    ecsClient.startFetchSchedule(window, (ecsData) => {
      const scheduledFetchScenario = logger.createScenario(
        Scenario.ScheduledFetchEcsConfig
      );
      const message = ecsData.ecsConfiguration
        ? `EcsRequestStatus: ${ecsData.requestStatus}, Config IDs: ${ecsData.ecsConfiguration.ConfigIDs.VirtualEventsPortal}`
        : `EcsRequestStatus: ${ecsData.requestStatus}`;
      scheduledFetchScenario?.mark(
        "ecsScheduledFetch - callback",
        EcsRequestStatus[ecsData.requestStatus],
        {
          message,
        }
      );

      dispatch(setEcsConfigAction(ecsData.ecsConfiguration)).then(() => {
        if (ecsData.ecsConfiguration) {
          scheduledFetchScenario?.stop();
        } else {
          const error = `ECS configuration is undefined. EcsRequestStatus: ${ecsData.requestStatus}`;
          scheduledFetchScenario?.fail({
            data: {
              error: JSON.stringify(error),
            },
          });
        }
      });
    });

    if (error) {
      scenario?.fail({
        data: {
          error: JSON.stringify(error),
        },
      });
    } else {
      scenario?.stop();
    }
  }
);

export const requestUpdateEcsConfigAction = createAsyncThunk(
  "ecs/config/requestUpdate",
  async (ecsClient: IEcsClient, { dispatch, getState }) => {
    const logger = Logger.getInstance();
    const scenario = logger.createScenario(Scenario.FetchEcsConfig);

    const filters: IEcsFilters = {};

    const tenantID = userTenantIDSelector(getState() as RootState);
    if (tenantID) {
      filters.TenantID = tenantID;
    }

    const userOID = userOIDSelector(getState() as RootState);
    if (userOID) {
      filters.UserID = userOID;
    }

    ecsClient.updateParameters(generateEcsParameters(filters));

    try {
      const ecsData = await ecsClient.fetch();
      const message = ecsData.ecsConfiguration
        ? `EcsRequestStatus: ${ecsData.requestStatus}, Config IDs: ${ecsData.ecsConfiguration.ConfigIDs.VirtualEventsPortal}`
        : `EcsRequestStatus: ${ecsData.requestStatus}`;
      scenario?.mark(
        "ecsFetch - resolved",
        EcsRequestStatus[ecsData.requestStatus],
        {
          message,
        }
      );

      await dispatch(setEcsConfigAction(ecsData.ecsConfiguration));

      if (ecsData.ecsConfiguration) {
        scenario?.stop();
      } else {
        const error = `ECS configuration is undefined. EcsRequestStatus: ${ecsData.requestStatus}`;
        scenario?.fail({
          data: {
            error: JSON.stringify(error),
          },
        });
      }
    } catch (error) {
      scenario?.mark("ecsFetch - rejected");

      await dispatch(setEcsConfigAction());

      scenario?.fail({
        data: {
          error: JSON.stringify(error),
        },
      });
    }
  }
);

export const setEcsConfigAction = createAsyncThunk(
  "ecs/config/set",
  async (config: IPortalEcsConfiguration | undefined) => {
    const logger = Logger.getInstance();
    const configIDs = config?.ConfigIDs.VirtualEventsPortal || "";
    logger.logTrace(
      LoggerLevels.info,
      `[setEcsConfigAction] Config IDs: ${configIDs}`
    );
    return config;
  }
);

export const ecsSlice = createSlice({
  name: "ecs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initEcsConfigAction.fulfilled, () => {})
      .addCase(
        setEcsConfigAction.fulfilled,
        (
          state: IEcsState,
          { payload }: PayloadAction<IPortalEcsConfiguration | undefined>
        ) => {
          const configIDs = payload?.ConfigIDs?.VirtualEventsPortal || "";
          const config = {
            ...initialEcsConfig,
            ...(payload?.VirtualEventsPortal || {}),
          };
          state.isConfigSet = true;
          state.configIDs = configIDs;
          state.config = config;
        }
      );
  },
});

// Selectors
export const isConfigSetSelector = (state: RootState): boolean =>
  state.ecs.isConfigSet;
// Config selectors.
// Keep in alphabetical order.
export const disableOneplayerIosSupportErrorMessageSelector = (
  state: RootState
): boolean => state.ecs.config.disableOneplayerIosSupportErrorMessage;
export const enableAccountSettingsPageSelector = (state: RootState): boolean =>
  state.ecs.config.enableAccountSettingsPage;
export const enableAddToCalenderSelector = (state: RootState): boolean =>
  state.ecs.config.enableAddToCalendar;
export const enableDownloadLogsButtonSelector = (state: RootState): boolean =>
  state.ecs.config.enableDownloadLogsButton;
export const enableEditAccountSelector = (state: RootState): boolean =>
  state.ecs.config.enableEditAccount;
export const enableEventOverviewApiSelector = (state: RootState): boolean =>
  state.ecs.config.enableEventOverviewApi;
export const enableEventSharingSocialButtonsSelector = (
  state: RootState
): boolean => state.ecs.config.enableEventSharingSocialButtons;
export const enablePublicProfilePageSelector = (state: RootState): boolean =>
  state.ecs.config.enablePublicProfilePage;
export const enableRedirectAcquireTokenSelector = (state: RootState): boolean =>
  state.ecs.config.enableRedirectAcquireToken;
export const enableRedirectLoginSelector = (state: RootState): boolean =>
  state.ecs.config.enableRedirectLogin;
export const enableCreateWebinarSelector = (state: RootState): boolean =>
  state.ecs.config.enableCreateWebinar;
export const enableSocialLoginSelector = (state: RootState): boolean =>
  state.ecs.config.enableSocialLogin;
export const enableSponsorsSelector = (state: RootState): boolean =>
  state.ecs.config.enableSponsors;
export const enableVODSelector = (state: RootState): boolean =>
  state.ecs.config.enableVODPlayback;
export const enableWebinarSeriesSelector = (state: RootState): boolean =>
  state.ecs.config.enableWebinarSeries;
export const enableWorkAccountProfilePagesSelector = (
  state: RootState
): boolean => state.ecs.config.enableWorkAccountProfilePages;
export const showEditAccountNonMvpFieldsSelector = (
  state: RootState
): boolean => state.ecs.config.showEditAccountNonMvpFields;

// reducer
export default ecsSlice.reducer;
