import { makeStyles, shorthands, tokens } from "../shared";
import { Breakpoint, mediaQueryBreakpointDown } from "./Grid";
export const speakerCardStyles = makeStyles({
  descriptionBox: {
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    display: "-webkit-box",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
    overflowWrap: "break-word",
  },
  speakerCardDescription: {
    whiteSpace: "pre-wrap",
  },
  socialIcon: {
    height: "16px",
    width: "16px",
    verticalAlign: "middle",
  },
  avatar: {
    height: "80px",
    width: "80px",
    fontSize: "34.2906px",
    fontWeight: tokens.fontWeightRegular,
  },
  speakerName: {
    fontSize: tokens.fontSizeBase500,
    verticalAlign: "middle",
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: "26px",
    textAlign: "left",
  },
  speakerNameDialog: {
    fontSize: "18px",
    verticalAlign: "middle",
    fontWeight: tokens.fontWeightSemibold,
    textAlign: "center",
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      fontSize: "17px",
      fontWeight: tokens.fontWeightBold,
      lineHeight: tokens.lineHeightBase400,
    },
  },
  socialIconsContainer: {
    "&": {
      verticalAlign: "middle",
      whiteSpace: "nowrap",
    },
    "& > :not(:last-child)": {
      marginRight: "6px",
    },
  },
  infoHeader: {
    "& > :not(:last-child)": {
      marginRight: "12px",
    },
    lineHeight: "26px",
    alignItems: "center",
    display: "flex",
  },
  userBio: {
    color: tokens.colorNeutralForeground3,
    lineHeight: tokens.lineHeightBase400,
    fontWeight: tokens.fontWeightMedium,
    fontSize: tokens.fontSizeBase400,
  },
  userBioDialog: {
    color: tokens.colorNeutralForeground3,
    lineHeight: tokens.lineHeightBase200,
    fontWeight: tokens.fontWeightRegular,
    fontSize: tokens.fontSizeBase200,
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      fontSize: "15px",
      lineHeight: tokens.lineHeightBase300,
    },
  },
  textAvatar: {
    fontSize: tokens.fontSizeHero900,
    fontWeight: tokens.fontWeightRegular,
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      fontSize: tokens.fontSizeHero800,
      height: "80px",
      width: "80px",
    },
  },
  infoText: {
    color: tokens.colorNeutralForeground3,
    width: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    lineHeight: tokens.lineHeightBase400,
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      fontSize: "15px",
      lineHeight: "20px",
    },
  },
});

export const speakerCardGridStyles = makeStyles({
  speakersGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    rowGap: "16px",
    columnGap: "16px",
    gridAutoRows: "1fr",
    [mediaQueryBreakpointDown(Breakpoint.Large)]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  gridBox: {
    backgroundcolor: tokens.colorNeutralBackground1,
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke3),
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    ...shorthands.padding("28px", "12px", "22px", "12px"),
    maxHeight: "240px",
    cursor: "pointer",
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      maxHeight: "172px",
      ...shorthands.padding("16px", "8px", "16px", "8px"),
    },
  },
  speakerCellTextStyles: {
    fontWeight: tokens.fontWeightSemibold,
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      fontSize: "17px",
      fontWeight: tokens.fontWeightBold,
      lineHeight: tokens.lineHeightBase400,
    },
  },
});
