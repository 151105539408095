import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  Method,
} from "axios";
import { AccountInfo } from "@azure/msal-common";
import { CorrelationVector } from "../../utilities/correlationVector/correlationVector";
import { CorrelationVectorVersion } from "../../utilities/correlationVector/correlationVectorVersion";
import {
  IAuthenticationService,
  IAccessToken,
  Scope,
  ISkypeToken,
  LoginState,
} from "../auth/authenticationService.interface";
import { encode } from "base64-arraybuffer";
import {
  IScenarioLogger,
  LoggerLevels,
  ITelemetryDetails,
  ITelemetryData,
} from "../../common/logger/interface";
import { Logger } from "../../common/logger/Logger";
import { CategoryName } from "../../common/brb/interface";
import { getSystemInfoText } from "../../common/brb/helpers";
import { RootState } from "../../core/store/store";
import { Store } from "redux";
import {
  TokenType,
  VISITOR_SKYPE_TOKEN_TENANT,
} from "../../configs/authConfigs";
import { Guid } from "../slices/CMDTypes";
import { PortalAxiosError, PortalAxiosErrorType } from "./PortalAxiosError";

export class AXHelper {
  private static _singleton: AXHelper | null = null;
  private static amsImageRez = "imgpsh_mobile_save";
  private loginState: LoginState;
  private authService: IAuthenticationService;
  private correlationVector: CorrelationVector;
  private eventTenantId: string;
  private store?: Store<RootState>;

  // NOTE: the x-ms-correlation-id is helpful to the CMDServices
  // team to help debug when a request goes awry.

  public makeTokenAuthHeader(token: string): AxiosRequestHeaders {
    return {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      "x-ms-correlation-id": this.incCorrelationVector(),
      Authorization: "Bearer " + token,
    };
  }

  public async makeTokenAuthHeaderForOrigin(
    account: AccountInfo,
    origin: string
  ): Promise<AxiosRequestHeaders> {
    const token = await this.authService.acquireToken(
      account,
      `${origin}/.default`
    );
    return {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      "x-ms-correlation-id": this.incCorrelationVector(),
      Authorization: "Bearer " + token.accessToken,
    };
  }

  public makeBadgerAuthHeader(token: string): AxiosRequestHeaders {
    return {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      "x-ms-correlation-id": this.incCorrelationVector(),
      Authorization: "Badger " + token,
    };
  }

  public makeSkypeAuthHeader(token: string): AxiosRequestHeaders {
    return {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      "x-ms-correlation-id": this.incCorrelationVector(),
      "x-skypetoken": token,
    };
  }

  public makeAMSHeader(
    token: string,
    tokenType: TokenType
  ): AxiosRequestHeaders {
    return {
      Accept: "*/*",
      "x-ms-correlation-id": this.incCorrelationVector(),
      Authorization: `${tokenType} ${token}`,
      "Cache-Control": "no-store",
    };
  }

  private setAuthTokenHeaders(resp: IAccessToken): AxiosRequestHeaders {
    return this.makeTokenAuthHeader(resp.accessToken);
  }

  private setSkypeTokenHeaders(resp: IAccessToken): AxiosRequestHeaders {
    return this.makeSkypeAuthHeader(resp.accessToken);
  }

  public static prefetchSocialShare(route: string): Promise<string> {
    if (!process.env.noop_api) return axios.get(route);
    return new Promise((resolve, reject) => resolve("prefetchSocialShare"));
  }

  private getAmsBaseUrl(response: ISkypeToken): string {
    const disableRegionRouting =
      this.store?.getState().ecs.config.disableRegionRouting;
    if (
      !disableRegionRouting &&
      response.regionGtms &&
      response.regionGtms.amsV2 &&
      response.regionGtms.amsV2 !== "NA" &&
      process.env.REACT_APP_REGIONALIZE === "true" &&
      this.loginState === LoginState.Work
    ) {
      return response.regionGtms.amsV2;
    }
    const amsBaseUrlOverride = this.store?.getState().ecs.config.baseAmsUrl;
    return amsBaseUrlOverride && amsBaseUrlOverride !== ""
      ? amsBaseUrlOverride
      : (process.env.REACT_APP_AMS_BASE as string);
  }

  private getVirtualEventsServiceUrl(response: ISkypeToken): string {
    const disableRegionRouting =
      this.store?.getState().ecs.config.disableRegionRouting;
    if (
      !disableRegionRouting &&
      response.regionGtms &&
      response.regionGtms.virtualEventsService &&
      response.regionGtms.virtualEventsService !== "NA" &&
      process.env.REACT_APP_REGIONALIZE === "true" &&
      this.loginState === LoginState.Work
    ) {
      return response.regionGtms.virtualEventsService;
    }
    const veServiceUrlOverride =
      this.store?.getState().ecs.config.virtualEventsServiceUrl;
    return veServiceUrlOverride && veServiceUrlOverride !== ""
      ? veServiceUrlOverride
      : (process.env.REACT_APP_CMD_SERVICES_URL as string);
  }

  // This gets an AMS image and converts it to a base64 string
  // for use with as an inline image
  public static async getAMSImage(
    imageID: string,
    imageSize = AXHelper.amsImageRez
  ): Promise<string> {
    const axHelper: AXHelper = AXHelper.getAXHelper();
    const enableAADTokenForAMS =
      axHelper.store?.getState().ecs.config.enableAADTokenForAMS;

    // get the skype token to ensure region url is set from skype token.
    const resp: ISkypeToken = await axHelper.authService.getSkypeTokenForAms(
      axHelper.eventTenantId
    );
    const userRegion = resp.region;
    const amsAfdEndpoint = axHelper.getAmsBaseUrl(resp);
    let headers;
    if (enableAADTokenForAMS && axHelper.isAuthenticated()) {
      // if we're authenticated, get the AAD token
      const aadToken: IAccessToken =
        await axHelper.authService.getAccessTokenForUser(Scope.IC3_GATEWAY);
      headers = axHelper.makeAMSHeader(
        aadToken.accessToken,
        TokenType.AadToken
      );
    } else {
      headers = axHelper.makeAMSHeader(resp.accessToken, TokenType.SkypeToken);
    }

    const data: ITelemetryData = {
      userRegion,
      amsAfdEndpoint,
      imageID,
    };
    const logger = Logger.getInstance();
    logger.logTrace(
      LoggerLevels.info,
      `[axHelper] [getAmsImage]: amsAfdEndpoint ${amsAfdEndpoint} imageId ${imageID}`
    );
    if (process.env.REACT_APP_CMD_SERVICES_URL === "http://localhost:5000") {
      const config: AxiosRequestConfig = {
        headers: headers,
      } as AxiosRequestConfig;
      const route = `http://localhost:5000/image/${imageID}`;
      const correlationID: string = headers["x-ms-correlation-id"] as string;
      const response = await logPerformance(
        "AXHelper.getAMSImage",
        () => axios.get<string>(route, config),
        route,
        "get",
        correlationID,
        data
      );
      return response.data;
    } else {
      const config: AxiosRequestConfig = {
        headers: headers,
        responseType: "arraybuffer",
      } as AxiosRequestConfig;
      const route = `${amsAfdEndpoint}/v1/objects/${imageID}/views/${imageSize}`;
      const response = await logPerformance(
        "AXHelper.getAMSImage",
        () => axios.get<ArrayBuffer>(route, config),
        route,
        "get",
        headers["x-ms-correlation-id"] as string,
        data
      );
      const b64: string = encode(response.data);
      const b64resp = `data:${response.headers["content-type"]};base64,${b64}`;
      return b64resp;
    }
  }

  public static makeConfig(
    headers: AxiosRequestHeaders,
    response: ISkypeToken
  ): AxiosRequestConfig {
    const axHelper: AXHelper = AXHelper.getAXHelper();
    return {
      headers: headers,
      baseURL: axHelper.getVirtualEventsServiceUrl(response),
      withCredentials: false,
    };
  }

  /**
   * Request to Virtual Events Service
   */
  public static async requestToServer<T = unknown, R = unknown>(
    route: string,
    method: Method,
    data: R,
    headers?: AxiosRequestHeaders,
    eventTenantId?: string,
    scenarioLogger?: IScenarioLogger
  ): Promise<T> {
    if (eventTenantId) {
      const axHelper: AXHelper = AXHelper.getAXHelper();
      axHelper.eventTenantId = eventTenantId;
    }

    const axHelper: AXHelper = AXHelper.getAXHelper();
    let config: AxiosRequestConfig = {};
    if (axHelper.isAuthenticated()) {
      // if we're authenticated, get the authentication token
      const resp: IAccessToken =
        await axHelper.authService.getAccessTokenForUser(
          Scope.CMD_SERVICES,
          scenarioLogger
        );

      // Ensure region url is set from skype token.
      const regionResp: ISkypeToken = await axHelper.authService.getSkypeToken(
        axHelper.eventTenantId
      );

      headers = { ...headers, ...axHelper.setAuthTokenHeaders(resp) };
      scenarioLogger?.mark("axHelper.setAuthTokenHeaders", undefined, {
        data: {
          correlationVector: headers["x-ms-correlation-id"] as string,
        },
      });
      config = AXHelper.makeConfig(headers, regionResp);
    } else {
      // otherwise, we get the user Skype token
      const resp: ISkypeToken = await axHelper.authService.getSkypeToken(
        axHelper.eventTenantId
      );
      headers = { ...headers, ...axHelper.setSkypeTokenHeaders(resp) };
      scenarioLogger?.mark("axHelper.setSkypeTokenHeaders", undefined, {
        data: {
          correlationVector: headers["x-ms-correlation-id"] as string,
        },
      });
      config = AXHelper.makeConfig(headers, resp);
    }
    scenarioLogger?.mark("axHelper.makeConfig", undefined, {
      data: {
        baseUrl: config.baseURL || "",
      },
    });

    config.url = route;
    config.method = method;
    if (data) {
      config.data = data;
    }

    const response = await logPerformance(
      "AXHelper.request",
      async () => {
        try {
          return await axios.request<T>(config);
        } catch (err) {
          if (err instanceof AxiosError) {
            throw PortalAxiosError.fromAxiosError(
              PortalAxiosErrorType.CMD_SERVICES,
              err
            );
          }
          throw err;
        }
      },
      route,
      method,
      headers["x-ms-correlation-id"] as string,
      {
        baseUrl: config.baseURL || "",
      },
      scenarioLogger
    );

    return response.data;
  }

  // GET method to Virtual Events Service: note we fetch authorization on each call in case oAuth2 needs to refresh
  // credentials.
  public static async getDataFromServer<T = unknown>(
    route: string,
    eventTenantId?: Guid,
    scenariologger?: IScenarioLogger
  ): Promise<T> {
    return AXHelper.requestToServer<T, undefined>(
      route,
      "get",
      undefined,
      undefined,
      eventTenantId,
      scenariologger
    );
  }

  // POST method to Virtual Events Service: note we fetch authorization on each call in case oAuth2 needs to refresh
  // credentials.
  public static async postDataToServer<T = unknown, R = unknown>(
    route: string,
    data: R,
    eventTenantId?: Guid,
    scenariologger?: IScenarioLogger
  ): Promise<T> {
    return AXHelper.requestToServer<T, R>(
      route,
      "post",
      data,
      undefined,
      eventTenantId,
      scenariologger
    );
  }

  // PUT method to Virtual Events Service: note we fetch authorization on each call in case oAuth2 needs to refresh
  // credentials.
  public static async putDataToServer<T = unknown, R = unknown>(
    route: string,
    data: R,
    eventTenantId?: Guid,
    scenariologger?: IScenarioLogger
  ): Promise<T> {
    return AXHelper.requestToServer<T, R>(
      route,
      "put",
      data,
      undefined,
      eventTenantId,
      scenariologger
    );
  }

  // PATCH method to Virtual Events Service: note we fetch authorization on each call in case oAuth2 needs to refresh
  // credentials.
  public static async patchDataToServer<T = unknown, R = unknown>(
    route: string,
    data: R,
    eventTenantId?: Guid,
    scenariologger?: IScenarioLogger
  ): Promise<T> {
    return AXHelper.requestToServer<T, R>(
      route,
      "patch",
      data,
      undefined,
      eventTenantId,
      scenariologger
    );
  }

  // DELETE method to Virtual Events Service: note we fetch authorization on each call in case oAuth2 needs to refresh
  // credentials.
  public static async deleteDataFromServer<T = unknown>(
    route: string,
    eventTenantId?: Guid,
    scenariologger?: IScenarioLogger
  ): Promise<T> {
    // if we're authenticated, get the authentication token
    return AXHelper.requestToServer<T, undefined>(
      route,
      "delete",
      undefined,
      undefined,
      eventTenantId,
      scenariologger
    );
  }

  /**
   * Wraps axios post adding some telemetry/logging.
   */
  public static post<T = unknown, R = AxiosResponse<T>, D = unknown>(
    source: string,
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
    correlationVector?: string,
    scenarioLogger?: IScenarioLogger
  ): Promise<R> {
    return logPerformance(
      source,
      () => axios.post(url, data, config),
      url,
      "post",
      correlationVector,
      undefined,
      scenarioLogger
    );
  }

  public static async brbClientAuthorization(
    categoryName: CategoryName,
    reportDescription: string,
    state: RootState,
    email?: string
  ): Promise<{
    BlobStorageUri: string;
    ContainerID: string;
    ContainerUrl: string;
    SASToken: string;
    SharedAccessSignature: string;
  }> {
    const axHelper: AXHelper = AXHelper.getAXHelper();

    const token = (await axHelper.authService.getSkypeTokenForBrb())
      .accessToken;

    const data = {
      ReportTitle: reportDescription.substring(0, 100),
      ReportDescription: reportDescription,
      CategoryName: categoryName,
      ClientID: "teams_virtual_events_portal",
      ClientVersion: "1.0.0.0", // TODO: Use our client version when BRB team updates the regex for this field.
      Ring: "general", // TODO: Remove ring after BRB team removes this requirement from the category.
      SkypeAuthToken: token,
      SystemInfo: getSystemInfoText(state),
      Email: email,
    };

    try {
      const response = await axios.post<{
        BlobStorageUri: string;
        ContainerID: string;
        ContainerUrl: string;
        SASToken: string;
        SharedAccessSignature: string;
      }>(
        `https://brbv2.skype.com/AuthService.svc/SubmitBRBReport/Teams/`,
        data
      );
      return response.data;
    } catch (e) {
      const logger = Logger.getInstance();
      logger.logTrace(LoggerLevels.error, `BRB authorization error: ${e}`);
      throw e;
    }
  }

  public static async brbUploadLog(
    containerUrl: string,
    sasToken: string,
    logFileName: string,
    logFileBody: string
  ): Promise<void> {
    const headers = {
      "x-ms-version": "2015-02-21",
      "x-ms-date": new Date().getTime().toString(),
      "x-ms-blob-type": "BlockBlob",
    };
    const uri = `${containerUrl}/${logFileName}?${sasToken}`;
    try {
      await axios.put(uri, logFileBody, {
        headers,
      });
    } catch (e) {
      const logger = Logger.getInstance();
      logger.logTrace(LoggerLevels.error, `BRB log file upload error: ${e}`);
      throw e;
    }
  }

  public static async brbRelease(containerId: string): Promise<void> {
    try {
      await axios.post(
        `https://brbv2.skype.com/AuthService.svc/release?container=${containerId}`
      );
    } catch (e) {
      const logger = Logger.getInstance();
      logger.logTrace(LoggerLevels.error, `BRB release error: ${e}`);
      throw e;
    }
  }

  private incCorrelationVector(): string {
    return this.correlationVector.increment();
  }

  protected constructor(authService: IAuthenticationService) {
    this.authService = authService;

    // assume we're not logged in
    this.loginState = LoginState.NotLoggedIn;

    // create our correlation vector
    this.correlationVector = CorrelationVector.createCorrelationVector(
      CorrelationVectorVersion.V2
    );

    // register our callback .. in case the are changes to auth status
    this.authService.registerAuthStateChangedCallback((loginState) => {
      this.setUserLoginState(loginState);
    });

    this.eventTenantId = VISITOR_SKYPE_TOKEN_TENANT;
  }

  public static createAXHelper(
    authService: IAuthenticationService,
    store: Store<RootState>
  ): void {
    if (!AXHelper.hasInstance()) {
      AXHelper._singleton = new AXHelper(authService);
      AXHelper._singleton.store = store;
    }
  }

  public static getAXHelper(): AXHelper {
    if (!AXHelper._singleton) {
      throw Error(
        "getAXHelper singleton not created. Call createAXHelper() first"
      );
    }
    return AXHelper._singleton;
  }

  public static hasInstance(): boolean {
    return AXHelper._singleton ? true : false;
  }

  public isAuthenticated(): boolean {
    return this.loginState !== LoginState.NotLoggedIn;
  }

  // We need to know if we're authenticated or not
  // to form the headers correctly
  public setUserLoginState(loginState: LoginState): void {
    this.loginState = loginState;
  }
}

function removeURLParams(url: string): string {
  if (url) {
    return url.split("?")[0];
  }
  return url;
}

async function logPerformance<T = unknown, R = AxiosResponse<T>>(
  source: string,
  axiosRequestCall: () => Promise<R>,
  route: string,
  method: string,
  /* istanbul ignore next */ correlationVector: string = "",
  data?: ITelemetryData,
  scenariologger?: IScenarioLogger
): Promise<R> {
  const logger = Logger.getInstance();
  const startTime = performance.now();
  const telemetry: ITelemetryDetails = {
    details: removeURLParams(route),
    method: method,
  };
  /* istanbul ignore else */
  if (data) {
    telemetry.data = data;
  }

  try {
    const response = await axiosRequestCall();
    const endTime = performance.now();
    logger.logPerformance(
      source,
      true,
      endTime - startTime,
      correlationVector,
      telemetry,
      scenariologger
    );
    return response;
  } catch (error) {
    const endTime = performance.now();
    // get the error message and the status
    if (error instanceof AxiosError && error.isAxiosError) {
      /* istanbul ignore if */
      if (!telemetry.data) {
        telemetry.data = {};
      }
      /* istanbul ignore else */
      if (telemetry.data) {
        if (error.response && "data" in error.response) {
          telemetry.data.ErrorCode =
            error.response.data?.ErrorCode ||
            error.response.data?.error?.code ||
            "";
          telemetry.data.ErrorMessage =
            error.response.data?.ErrorMessage ||
            error.response.data?.error?.message ||
            "";
        }
        telemetry.data.message = error?.message;

        if (error.response) {
          telemetry.data.status = error.response?.status;
        }
      }
    }
    logger.logPerformance(
      source,
      false,
      endTime - startTime,
      correlationVector,
      telemetry,
      scenariologger
    );
    throw error;
  }
}
